import { ExternalLink, Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
// TODO: Use our own icon set from CbhIcon
// eslint-disable-next-line no-restricted-imports
import EditRoundedIcon from "@mui/icons-material/EditRounded";
// TODO: Use our own Button
// eslint-disable-next-line no-restricted-imports
import { Button, CardContent, Stack } from "@mui/material";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { type Worker } from "@src/appV2/Worker/api/types";

import { Card } from "../../components/Card";
import { UploadCandidateRequirementsDialog } from "./UploadCandidateRequirementsDialog";

interface UploadedCandidateRequirementsProps {
  placementCandidateId: string;
  introductionUrl?: string;
  worker: Worker;
}

export function UploadedCandidateRequirements(props: UploadedCandidateRequirementsProps) {
  const { introductionUrl, worker, placementCandidateId } = props;
  const reuploadRequirementModalState = useModalState();
  return (
    <Card variant="tertiary">
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Text variant="h5">Introduction Video</Text>

          <Stack direction="row" alignItems="center" spacing={1}>
            <Button
              startIcon={<EditRoundedIcon fontSize="small" />}
              variant="text"
              size="small"
              onClick={() => {
                logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_REQUIREMENTS_REUPLOAD_CLICKED, {
                  placementCandidateId,
                });
                reuploadRequirementModalState.openModal();
              }}
            >
              Edit
            </Button>
            {isDefined(introductionUrl) && <ExternalLink to={introductionUrl}>View</ExternalLink>}
          </Stack>
        </Stack>
      </CardContent>
      {isDefined(introductionUrl) && reuploadRequirementModalState.modalIsOpen && (
        <UploadCandidateRequirementsDialog
          placementCandidateId={placementCandidateId}
          modalState={reuploadRequirementModalState}
          worker={worker}
        />
      )}
    </Card>
  );
}
