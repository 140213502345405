import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useUpdatePlacementCandidate } from "../api/useUpdatePlacementCandidate";
import { type PlacementCandidate, type PlacementCandidateMarketingLinkClicks } from "../types";

const MARKETING_LINK_URL_PARAM = "placementsMarketingSource";
const MARKETING_LINK_SOURCES = new Set(["notYetActivated", "notYetSubmittedApplication"]);

function getUpdatedMarketingClicks(
  // eslint-disable-next-line @typescript-eslint/ban-types
  source: string | null | undefined,
  currentMarketingLinkClicks: PlacementCandidateMarketingLinkClicks
): PlacementCandidateMarketingLinkClicks | undefined {
  if (!source || !MARKETING_LINK_SOURCES.has(source)) {
    return undefined;
  }

  const updatedClicks: Record<string, number | undefined> = {
    ...currentMarketingLinkClicks,
  };

  updatedClicks[source] = (updatedClicks[source] ?? 0) + 1;

  return updatedClicks;
}

export function useTrackMarketingLinkClicks(
  placementCandidate?: Pick<PlacementCandidate, "id" | "marketingLinkClicks">
) {
  const history = useHistory();

  const { mutateAsync: updatePlacementCandidate } = useUpdatePlacementCandidate();

  useEffect(() => {
    if (!placementCandidate) {
      return;
    }

    const { id, marketingLinkClicks } = placementCandidate;

    const searchParams = new URLSearchParams(history.location.search);
    const source = searchParams.get(MARKETING_LINK_URL_PARAM);

    // make sure we only process the query param once
    searchParams.delete(MARKETING_LINK_URL_PARAM);
    history.replace({
      search: searchParams.toString(),
    });

    const updatedClicks = getUpdatedMarketingClicks(source, marketingLinkClicks ?? {});

    if (!updatedClicks) {
      return;
    }

    void updatePlacementCandidate({
      placementCandidateId: id,
      marketingLinkClicks: updatedClicks,
    });
  }, [history, placementCandidate, updatePlacementCandidate]);
}
